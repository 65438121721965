<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="row m-5">
          <div class="col-4">
            <div class="mainBody-card p-5">
              <button class="btn-icon-def mx-3">
                <i class="fa fa-map-marker"></i>
              </button>
              <span class="subheader-home"
                >Blog 174, First Floor, Office 3, C , Ring Rd,</span
              >
            </div>
          </div>
          <div class="col-4">
            <div class="mainBody-card p-5">
              <button class="btn-icon-def mx-3">
                <i class="fa fa-phone"></i>
              </button>
              <span class="subheader-home"
                >Blog 174, First Floor, Office 3, C , Ring Rd,</span
              >
            </div>
          </div>
          <div class="col-4">
            <div class="mainBody-card p-5">
              <button class="btn-icon-def mx-3 p-1">
                <i class="fa fa-envelope-o"></i>
              </button>
              <span class="subheader-home"
                >Blog 174, First Floor, Office 3, C , Ring Rd,</span
              >
            </div>
          </div>
        </div>

        <div class="row m-5">
          <div class="col-12 mt-8 d-flex justify-content-around">
            <div class="d-none d-xl-block">
              <div>
                <img src="../../assets/images/backgrounds/NoPath3.png" />
              </div>
            </div>
            <div class="" :class="t_ar">
              <div class="cart-header">
                {{ $t("contactUs") }}
              </div>
              <div class="about-header mt-3">
                {{ $t("dontHesitate") }}
              </div>
              <form :class="flex_ar">
                <div class="form-group mt-5">
                  <div class="col-12">
                    <label class="input-label" :class="f_ar">
                      {{ $t("col_name") }}
                    </label>
                    <input
                      v-model="text"
                      name="name"
                      id="name"
                      class="form-control text-input-bot"
                      :placeholder="$t('col_name')"
                    />
                  </div>
                </div>
                <div class="form-group mt-5">
                  <div class="col-12">
                    <label class="input-label" :class="f_ar">
                      {{ $t("col_email") }}
                    </label>
                    <input
                      v-model="email"
                      name="email"
                      id="email"
                      class="form-control text-input-bot"
                      :placeholder="$t('col_email')"
                    />
                  </div>
                </div>
                <div class="form-group mt-5">
                  <div class="col-12">
                    <label class="input-label" :class="f_ar">
                      {{ $t("Subject") }}
                    </label>
                    <input
                      v-model="subject"
                      name="subject"
                      id="subject"
                      class="form-control text-input-bot"
                      :placeholder="$t('Subject')"
                    />
                  </div>
                </div>
                <div class="form-group mt-5">
                  <div class="col-12">
                    <label class="input-label" :class="f_ar">
                      {{ $t("Message") }}
                    </label>
                    <textarea
                      v-model="message"
                      name="message"
                      id="message"
                      class="form-control text-input-bot"
                      :placeholder="$t('Message')"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      email: "",
      subject: "",
      message: "",
    };
  },
  computed: {
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
  },
};
</script>